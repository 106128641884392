
<div class="column">
            <div class="logo-bank">
                <img [src]="'/assets/images/' + tarjeta.type.toLowerCase() + '.png'" alt="{{ tarjeta.type }}">

            </div>
            <div class="credit_data">
                <h4 class="tx_tipo">{{ tarjeta.type }}</h4>
                <p class="tc_number">Bank {{ tarjeta.number}}</p>
                <span class="verified">{{ tarjeta.status == 1 ? "Activa" : "Inactiva" }}</span>
            </div>
            <div *ngIf="tarjeta.status == 1" class="col-md-5 col-lg-4"><button (click)="changeStatus(tarjeta)" class="outline w-100 btn btn-primary btn-lg" type="submit">Desactivar</button></div>
            <div *ngIf="tarjeta.status == 0"  class="col-md-5 col-lg-4"><button (click)="changeStatus(tarjeta)" class="outline w-100 btn btn-primary btn-lg" type="submit">Activar</button></div>
</div>
