
<body [ngClass]="themeService.currentPalette">
    <div class="main-content">
        <div  class="background">
            <div>
                <app-header  [navigation]="aloudNavigation"  class="navbar"></app-header>
            </div>
            <div class="main-image" [style.background-image]="'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(' + imagePrincipal + ')'">
                
                <div class="text">
                    <h1 *ngIf="isSuscriptionActive">{{ title }}</h1>
                    <h1 *ngIf="!isSuscriptionActive">PORTAL EN MANTENIMIENTO</h1>
                    <p>{{ description }}</p>
                 <!--a href="#planes-section" class="btn btn-primary">Planes</a-->
                </div>
            </div>
            
        </div>
        <div class="background2" id="planes-section">
            <div class="plans">
                <p class="bar">{{price_title}} </p>
                <div>
                    <app-plan-basic [navigation]="aloudNavigation" ></app-plan-basic>
                </div>
            </div>
        </div>
        
        <div class="background-banner">
            <div class="banner-content">
                <div class="text">
                    <h1>{{ banner_title }}</h1>
                <p>{{ banner_description }}</p>
                </div>
                <div class="white-image" [style.background-image]="
                'url(' + imagePromotional + ')'">
                </div>
            </div>
        </div>
        <!-- <div [ngClass]="{
            'footer': selectedPalette === 'Billcentrix',
            'footer-modern': selectedPalette === 'Moderna',
            'footer-violet': selectedPalette === 'Violeta'
          }" >
            <h2>Texto en el pie de página</h2>
            <hr>
            <div class="mail">
                <img *ngIf="selectedPalette === 'Billcentrix'" src="../../../assets/icons/mail.png" alt="icono de mail">
                <img *ngIf="selectedPalette === 'Moderna'" src="../../../assets/icons/mail-green.png" alt="icono de mail">
                <img *ngIf="selectedPalette === 'Violeta'" src="../../../assets/icons/mail-fucsia.png" alt="icono de mail">
                <h2>Forma@<strong>decontacto.com</strong></h2>
            </div>
        </div> -->
        <div class="footer">
            <p>Copyright © 2022 <a href="https://billcentrix.com/">Billcentrix</a>. All Rights Reserved.</p>
        </div>
    </div>

</body>