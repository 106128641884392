<div class="plan-basic" [ngClass]="themeService.currentPalette">
  <div class="plan-basic-content" *ngIf="plans?.length">
    <div class="carousel-controls" *ngIf="plans.length > 3">
      <button class="carousel-nav-left" (click)="prevSlide()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button class="carousel-nav-right" (click)="nextSlide()">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
    <div class="carousel carru">
      <div *ngFor="let plan of visiblePlans" class="slider">
        <div class="carousel-container">
          <div class="preview">
            <p>{{ plan.getName() }}</p>
            <img class="image-prod" [src]="plan.getImage()" alt="imagen planes">
            <h1 class="big-numbers">${{ plan.getPrice() }}</h1>
            <div class="description">
              <p #descripcionPlan>{{ plan.getDescription() }}</p>
            </div>
            <button (click)="onSelectPlan(plan)" class="custom-button">
              Seleccionar
            </button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>
