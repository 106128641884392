 <!-- app-suscription -->
 <div class="card-body">
    <div *ngFor=" let sus of suscriptions" class="row">

        <div class="column">
            <div class="logo-bank">
                <img src="/assets/images/subscription.png" alt="">
            </div>
            <div class="credit_data">
                <h4 class="tx_tipo"> {{ sus.plan.name_plan }} 
                    <span class="estatus" [ngClass]="{
                        'status-activa': sus.status_customerPlan === 'Activa',
                        'status-inactiva': sus.status_customerPlan === 'Inactiva',
                        'status-congelada': sus.status_customerPlan === 'Congelada',
                        'status-en-proceso-de-cobro': sus.status_customerPlan === 'En proceso de cobro',
                        'status-no-se-pudo-cobrar': sus.status_customerPlan === 'No se pudo cobrar'
                      }">{{ sus.status_customerPlan }} 
          </span>
                    </h4>
                <p class="tc_number"> Costo ${{ sus.plan.feeCost_plan }} </p>
                <span class="verified"> Próxima facturación es el {{ sus.next_bill_date }} </span>
            </div>
            <button class="disable" (click)="onUpdate(sus)">Actualizar</button>
        </div>

    </div>
 </div>
