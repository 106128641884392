import { Component, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { Plan } from 'src/app/components/plan/model/plan.model';
import { PlanService } from 'src/app/services/plan/plan.service';
import { ThemeService } from '../../theme.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-plan-basic',
  templateUrl: './plan-basic.component.html',
  styleUrls: ['./plan-basic.component.scss']
})
export class PlanbasicComponent implements OnInit, OnDestroy {
  plans: Plan[] = [];
  currentIndex = 0;
  visiblePlans: Plan[] = [];
  itemsToShow = 3; // Variable para controlar la cantidad de elementos visibles
  @Input() navigation = true;

  isPreview: boolean = false;
  param: any;
  private intervalId: any;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    public themeService: ThemeService,
    private planService: PlanService
  ) {
    this.activateRoute.params.subscribe(params => {
      this.isPreview = params["id"] !== undefined;
      if (this.isPreview) {
        this.param = params["id"];
      }
    });
  }

  ngOnInit() {
    this.updateItemsToShow(window.innerWidth); // Establece la cantidad inicial de elementos visibles
    this.planService.obtainPlans.subscribe((plans: Plan[]) => {
      if (plans) {
        this.plans = plans.filter(plan => plan.isVisiblePlan());
        this.updateVisiblePlans();
        this.planService.setIsActive(true);
        this.initCarousel();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateItemsToShow(event.target.innerWidth);
    this.updateVisiblePlans(); // Actualiza los elementos visibles al cambiar el tamaño de la pantalla
  }

  updateItemsToShow(width: number) {
    if (width >= 1200) {
      this.itemsToShow = 3; // Mostrar 3 elementos en pantallas grandes
    } else if (width >= 768) {
      this.itemsToShow = 2; // Mostrar 2 elementos en pantallas medianas
    } else {
      this.itemsToShow = 1; // Mostrar 1 elemento en pantallas pequeñas
    }
  }

  initCarousel() {
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, 5000); // Cambia el tiempo según sea necesario
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateVisiblePlans() {
    this.visiblePlans = [];

    for (let i = 0; i < this.itemsToShow; i++) {
      const index = (this.currentIndex + i) % this.plans.length;
      this.visiblePlans.push(this.plans[index]);
    }
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex - 1 + this.plans.length) % this.plans.length;
    this.updateVisiblePlans();
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.plans.length;
    this.updateVisiblePlans();
  }

  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }

  onSelectPlan(plan: Plan) {
    this.planService.setPlanSelected(plan.getID());
    if (this.isPreview) {
      this.router.navigate(["/preview/" + this.param + "/checkout"], { relativeTo: this.activateRoute, queryParamsHandling: "preserve" });
    } else {
      this.router.navigate(["/checkout"], { relativeTo: this.activateRoute, queryParamsHandling: "preserve" });
    }
  }
}
