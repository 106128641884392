import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-update-address',
  templateUrl: './update-address.component.html',
  styleUrls: ['./update-address.component.scss']
})
export class UpdateAddressComponent {

  provinces: any[]= [] ;
  form: any ;
  corregimientos: any[]= [] ;

  constructor(private auth : AuthService,private webService : WebService, private notification : NotificationService, private loader : LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateAddressComponent>) { 
      this.loader.open();  
      this.form = new FormGroup({
        id: new FormControl(this.data.item.id, Validators.required),
        adress: new FormControl(this.data.item.address, Validators.required),
        province : new FormControl(this.data.item.province, Validators.required),        
        corregimiento : new FormControl(this.data.item.corregimiento, Validators.required)
      });

      this.webService.get(this.webService.HOST + "/province").subscribe( provinces =>{
        this.loader.close();
        this.provinces = provinces.result;
        this.corregimientos = [];
        this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == +this.form.value.province)].corregimientos;
      }, err =>{
        this.loader.close()
        this.notification.showError(err);
      });

    }

    onProvinceChange(){
      this.corregimientos = [];
      this.form.controls['corregimiento'].setValue('');
      this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == +this.form.value.province)].corregimientos;
    }

    closeDialog(): void {
      this.dialogRef.close();
    }
  
  
    update(){
  
      if(!this.form.valid){
        return this.notification.showError("Por favor complete el formulario")
      }
  
      this.loader.open();
      this.form.value.customer = { id: this.auth.getCustomer() } ;
      // this.form.value.status = 1;
      this.form.value.province = {id: Number(this.form.value.province) };
      this.form.value.corregimiento = {id: Number(this.form.value.corregimiento) }; // ESTO ESTA FIJO
      
      this.webService.put(this.form.value, this.webService.HOST + "/adress/" + this.form.value.id ).subscribe(res =>{
        this.notification.showSuccess("Actualizada correctamente");
        this.closeDialog();
      }, err=>{
        this.loader.close();
        this.notification.showError(err);
      })
      
    }
  

}
